import clsx from "clsx";
import { ReactNode } from "react";

import { TextColors } from "types/theme.types";

export interface TypographyProps {
  className?: string;
  color?: TextColors;
  children?: ReactNode;
  size?: string;
  weight?: string;
  type?: "Serif" | "Sans";
}

export function H1(props: TypographyProps) {
  const className = clsx(
    "font-title",
    "leading-none",
    "text-6xl",
    "md:text-8xl",
    "tracking-tightest",
    "whitespace-pre-line",
    props.className,
    props.color
  );
  return <h1 className={className}>{props.children}</h1>;
}

export function H2(props: TypographyProps) {
  const className = clsx(
    props.weight ?? "font-light",
    "font-title",
    "leading-tight",
    "text-3xl",
    "md:text-6xl",
    "lg:text-7xl",
    "whitespace-pre-line",
    props.className,
    props.color
  );
  return <h2 className={className}>{props.children}</h2>;
}

export function H3(props: TypographyProps) {
  const className = clsx(
    props.weight ?? "font-light",
    "font-title",
    "leading-tight",
    "text-2xl",
    "md:text-5xl",
    "whitespace-pre-line",
    props.className,
    props.color
  );
  return <h3 className={className}>{props.children}</h3>;
}

export function H4(props: TypographyProps) {
  const className = clsx(
    props.type === "Sans" ? "font-title" : "font-subtitle",
    "leading-snug",
    "text-2xl",
    "md:text-4xl",
    "whitespace-pre-line",
    props.className,
    props.color
  );
  return <h4 className={className}>{props.children}</h4>;
}

export function H5(props: TypographyProps) {
  const className = clsx(
    props.type === "Sans" ? "font-title" : "font-subtitle",
    "leading-snug",
    "text-lg",
    "md:text-2xl",
    "whitespace-pre-line",
    props.className,
    props.color
  );
  return <h5 className={className}>{props.children}</h5>;
}

export function H6(props: TypographyProps) {
  const className = clsx(
    "font-title",
    "leading-snug",
    "md:text-lg",
    "tracking-widest",
    "uppercase",
    "whitespace-pre-line",
    props.className,
    props.color
  );
  return <h6 className={className}>{props.children}</h6>;
}

export function BodyLg(props: TypographyProps) {
  const className = clsx(
    "font-content",
    "leading-normal",
    "text-xl",
    "whitespace-pre-line",
    props.className,
    props.color
  );
  return <p className={className}>{props.children}</p>;
}

export function Body(props: TypographyProps) {
  const className = clsx(
    "font-content",
    "leading-normal",
    "whitespace-pre-line",
    props.size,
    props.className,
    props.color
  );
  return <p className={className}>{props.children}</p>;
}

export function BodySm(props: TypographyProps) {
  const className = clsx(
    "font-content",
    "leading-normal",
    "text-sm",
    "whitespace-pre-line",
    props.className,
    props.color
  );
  return <p className={className}>{props.children}</p>;
}

const Typography = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  BodyLg,
  Body,
  BodySm,
};

export default Typography;
