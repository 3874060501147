import { CacheKeys } from "enum/cache-keys";

import { getKeyFromStorage, setStorageKey } from "lib/utils";

const CacheKey = CacheKeys.webVisitUrl;

export const getWebVisitUrl = (): string => {
  return getKeyFromStorage(CacheKey);
};

export const setWebVisitUrl = () => {
  setStorageKey(CacheKey, window.location.href);
};

export const getLastWebVisitUrl = (): string => {
  return getKeyFromStorage(CacheKeys.lastVisitUrl);
};
