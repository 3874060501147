export const jsonParser = <T extends object>(
  key: string,
  defaultReturn?: T
) => {
  try {
    return JSON.parse(key);
  } catch (error) {
    console.error(error);
    return defaultReturn;
  }
};

export const jsonStringifier = <T extends object>(
  object: T,
  defaultReturn?: string
) => {
  try {
    return JSON.stringify(object);
  } catch (error) {
    console.error(error);
    return defaultReturn;
  }
};
