import { getAnonymousId } from "lib/anonymous-id";
import { ExperimentData } from "lib/flagsmith";
import { Tryer } from "lib/tracker/utils";

export const API_KEY = process.env.NEXT_PUBLIC_SEGMENT_API_KEY;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}
/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable prefer-rest-params */
export function init() {
  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || []);
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error("Segment snippet included twice.");
    }
    return;
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    "trackSubmit",
    "trackClick",
    "trackLink",
    "trackForm",
    "pageview",
    "identify",
    "reset",
    "group",
    "track",
    "ready",
    "alias",
    "debug",
    "page",
    "once",
    "off",
    "on",
    "addSourceMiddleware",
    "addIntegrationMiddleware",
    "setAnonymousId",
    "addDestinationMiddleware",
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };
  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }
  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create a deferred script element based on your key.
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = true;
    script.src =
      "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
    // Insert our script next to the first script element.
    const first = document.getElementsByTagName("script")[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };
  analytics._writeKey = API_KEY;
  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = "4.13.2";
  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(API_KEY);
  // Use the UUID generated by middleware that will be used by Flagsmith
  const sv_anonymous_id = getAnonymousId();
  if (sv_anonymous_id) {
    analytics.setAnonymousId(sv_anonymous_id);
  }
  // Return for immediate usage
  return analytics;
}

const tryTracking = Tryer("analytics", init);

export function trackPageView(pageName: string, properties?: object): void {
  // tryTracking(
  //   "segment.trackPageView",
  //   () => window.analytics.page(pageName, properties),
  //   {
  //     pageName,
  //     properties,
  //   }
  // );
}

export function trackCustomEvent(eventName: string, properties?: object): void {
  // tryTracking(
  //   "segment.trackCustomEvent",
  //   () => window.analytics.track(eventName, { ...properties }),
  //   {
  //     eventName,
  //     properties,
  //   }
  // );
}
/* eslint-enable unicorn/prefer-query-selector */
/* eslint-enable prefer-rest-params */

export function trackExperiment(experimentData: ExperimentData): void {
  const { experimentName, variantName } = experimentData;

  // Format expected by Mixpanel
  // https://help.mixpanel.com/hc/en-us/articles/360038439952-Experiments#add-experiments-to-an-implementation
  const mixpanelData = {
    "Experiment name": experimentName,
    "Variant name": variantName,
  };

  // tryTracking(
  //   "segment.trackExperiment",
  //   () => window.analytics.track(`$experiment_started`, mixpanelData),
  //   mixpanelData
  // );
}

export function identifyUser(userId?: string, userData?: object) {
  // tryTracking(
  //   "segment.identifyUser",
  //   () => window.analytics.identify(userId, { ...userData }),
  //   {
  //     userId,
  //     userData,
  //   }
  // );
}
