import * as Sentry from "@sentry/nextjs";

import themeColors from "lib/preval/tailwind.theme-colors";
import themeScreens from "lib/preval/tailwind.theme-screens";

import { TextColors } from "types/theme.types";

const BREAKPOINTS_VARIANTS = {
  sm: "mobile",
  md: "desktop",
  lg: "desktop",
  xl: "desktop",
  "2xl": "desktop",
} as const;

type ScreenBreakpoint = keyof typeof BREAKPOINTS_VARIANTS;

const getBreakpointValue = (value: string) =>
  Number.parseInt(themeScreens[value].replace("px", ""));

const getCurrentScreenBreakpoint = () => {
  let currentScreenBreakpoint: ScreenBreakpoint = "sm";
  let maxBreakpointValue = 0;

  for (const breakpoint of Object.keys(themeScreens)) {
    const breakpointValue = getBreakpointValue(breakpoint);
    const breakpointMatches = window.matchMedia(
      `(min-width: ${themeScreens[breakpoint]})`
    ).matches;
    if (breakpointMatches && breakpointValue > maxBreakpointValue) {
      maxBreakpointValue = breakpointValue;
      currentScreenBreakpoint = breakpoint as ScreenBreakpoint;
    }
  }
  return currentScreenBreakpoint;
};

export const getScreenVariant = () => {
  const currentScreenBreakpoint = getCurrentScreenBreakpoint();
  const screenVariant = BREAKPOINTS_VARIANTS[currentScreenBreakpoint];
  if (!screenVariant) {
    Sentry.captureMessage("Screen Variant not recognized", {
      extra: { screenVariant },
    });
  }
  return screenVariant;
};

export const getHexFromColorName = (color: TextColors) => {
  const plainColor = color?.replace("text-", "");
  return themeColors[plainColor];
};
