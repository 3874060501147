import every from "lodash/every";

import { flagsmithBrowser } from "lib/flagsmith/flagsmith-browser";
import { getScreenVariant } from "lib/tailwind";
import * as bing from "lib/tracker/bing";
import * as facebook from "lib/tracker/facebook";
import * as ga from "lib/tracker/google-analytics";
import * as ga4 from "lib/tracker/gtag";
import * as linkedin from "lib/tracker/linkedin";
import * as segment from "lib/tracker/segment";
import * as tiktok from "lib/tracker/tiktok";
import { getUTMSFromStorage, prefixKeys } from "lib/utils";

import { ApplicationFlows } from "types/apply.types";
import { AdBuckets } from "types/assessment.types";

export { getFacebookCookies } from "lib/tracker/facebook";
export {
  getGoogleUACookies,
  getClientId as getGoogleClientId,
} from "lib/tracker/google-analytics";

export { getClientId as getGa4ClientId, getGclid } from "lib/tracker/gtag";

export type ChannelsForTracking = {
  google?: boolean;
  facebook?: boolean;
  segment?: boolean;
  bing?: boolean;
  tiktok?: boolean;
  linkedin?: boolean;
};

export const CHANNELS_ENABLED_DEFAULTS = {
  facebook: true,
  segment: true,
  google: true,
  bing: true,
  tiktok: true,
  linkedin: true,
};

export function pipeChannelsEnabled(
  channelsForTrackingMerge: ChannelsForTracking
) {
  const channels = {
    ...CHANNELS_ENABLED_DEFAULTS,
    ...channelsForTrackingMerge,
  };
  return (key: keyof ChannelsForTracking): boolean => {
    return channels[key] === true;
  };
}

export function isChannelEnabled(
  channelsForTrackingMerge: ChannelsForTracking,
  key: keyof ChannelsForTracking
): boolean {
  return pipeChannelsEnabled(channelsForTrackingMerge)(key);
}

export function trackPageView(
  pageName: string,
  properties: object,
  channelsForTrackingMerge: ChannelsForTracking
): void {
  const screenVariant = getScreenVariant();
  const screenVariantProperties = {
    screen_variant: screenVariant,
    window_inner_width: window.innerWidth,
  };

  const trackPageViewEnabledOn = pipeChannelsEnabled(channelsForTrackingMerge);
  if (trackPageViewEnabledOn("facebook")) {
    facebook.track("track", "PageView");
  }
  if (trackPageViewEnabledOn("segment")) {
    segment.trackPageView(pageName, {
      ...properties,
      ...screenVariantProperties,
    });
  }
  if (trackPageViewEnabledOn("google")) {
    ga.trackPageView();
  }
  if (trackPageViewEnabledOn("bing")) {
    bing.trackPageView();
  }
  if (trackPageViewEnabledOn("tiktok")) {
    tiktok.trackPageView();
  }
  if (trackPageViewEnabledOn("linkedin")) {
    linkedin.trackPageView();
  }
}

export function trackLastWebVisitUTMs() {
  const utms = getUTMSFromStorage("last");
  const areAllValuesEmpty = every(utms, (value) => !value);
  if (areAllValuesEmpty === false) {
    identifyUser(undefined, prefixKeys(utms, "last_web_visit_"));
  }
}

export function trackExperiment(experimentId: string): void {
  if (flagsmithBrowser.hasExperiment(experimentId)) {
    const experimentData = flagsmithBrowser.getExperimentData(experimentId);
    segment.trackExperiment(experimentData);
  }
}

export function applicationStarted(
  channelsForTrackingMerge: ChannelsForTracking,
  flow: ApplicationFlows
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("app_start", { flow });
  }
}

export function leadCompleted(
  isPotentiallyQualified: boolean,
  leadContext: string,
  channelsForTrackingMerge: ChannelsForTracking,
  userId: string,
  flow: ApplicationFlows
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("lead_complete", { flow });
  }

  const stepCompletedEnabledOn = pipeChannelsEnabled(channelsForTrackingMerge);

  if (stepCompletedEnabledOn("facebook")) {
    facebook.track("track", "Lead");
  }
  if (stepCompletedEnabledOn("google")) {
    ga.trackEvent({
      category: "Funnel",
      action: "Lead",
      label: leadContext,
    });
    ga4.trackEvent("Lead");
  }
  if (stepCompletedEnabledOn("bing")) {
    bing.trackEvent("Lead");
  }
  if (stepCompletedEnabledOn("tiktok")) {
    tiktok.trackEvent("AddToWishlist");
  }
  if (stepCompletedEnabledOn("linkedin")) {
    linkedin.trackEvent("Lead");
  }
  if (isPotentiallyQualified) {
    if (stepCompletedEnabledOn("tiktok")) {
      tiktok.trackEvent("AddToCart");
    }
    if (stepCompletedEnabledOn("google")) {
      ga.setUserId(userId);
      ga4.setUserId(userId);
      ga.trackEvent({
        category: "Funnel",
        action: "PotentialQualifiedLead",
        label: leadContext,
      });
      ga4.trackEvent("PotentialQualifiedLead");
    }
  }
}

export function leadStarted(
  channelsForTrackingMerge: ChannelsForTracking,
  flow: ApplicationFlows
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("lead_start", { flow });
  }
}

export function applyStepStarted(
  application_id: string,
  step_number: number,
  step_name: string,
  channelsForTrackingMerge: ChannelsForTracking,
  flow: ApplicationFlows
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("app_step_start", {
      application_id,
      step_number,
      step_name,
      flow,
    });
  }
}

export function applyStepCompleted(
  application_id: string,
  step_number: number,
  step_name: string,
  channelsForTrackingMerge: ChannelsForTracking,
  flow: ApplicationFlows
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("app_step_complete", {
      application_id,
      step_number,
      step_name,
      flow,
    });
  }
}

// bing + tiktok
export function applicationCompleted(
  isQualified: boolean,
  leadContext: string,
  application_id: string,
  channelsForTrackingMerge?: ChannelsForTracking
): void {
  const applicationCompletedEnabledOn = pipeChannelsEnabled(
    channelsForTrackingMerge
  );
  if (applicationCompletedEnabledOn("segment")) {
    segment.trackCustomEvent("app_complete", {
      application_id: application_id,
    });
  }
  if (applicationCompletedEnabledOn("google")) {
    ga.trackEvent({
      category: "Funnel",
      action: "SubmitApplication",
      label: leadContext,
    });
    ga4.trackEvent("SubmitApplication");
  }
  if (applicationCompletedEnabledOn("bing")) {
    bing.trackEvent("SubmitApplication");
  }

  if (isQualified) {
    if (applicationCompletedEnabledOn("google")) {
      ga.trackEvent({
        category: "Funnel",
        action: "MQL",
        label: leadContext,
      });
    }
    if (applicationCompletedEnabledOn("bing")) {
      bing.trackEvent("MQL");
    }
    if (applicationCompletedEnabledOn("tiktok")) {
      tiktok.trackEvent("InitiateCheckout");
    }
    if (applicationCompletedEnabledOn("linkedin")) {
      linkedin.trackEvent("MQL");
    }
  }
}

export function applyResult(
  application_id: string,
  is_qualified: boolean,
  sent_to: string,
  channelsForTrackingMerge: ChannelsForTracking,
  step_number: number,
  step_name: string,
  flow: ApplicationFlows
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("app_result", {
      application_id,
      step_number,
      step_name,
      status: is_qualified ? "qualified" : "unqualified",
      sent_to,
      flow,
    });
  }
}

export function assessmentStarted(
  application_id: string,
  flow: string,
  channelsForTrackingMerge?: ChannelsForTracking
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("wa_start", {
      application_id: application_id,
      flow: flow,
    });
  }
}

export function assessmentCompleted(
  leadContext: string,
  application_id: string,
  flow: string,
  bucket: AdBuckets,
  channelsForTrackingMerge?: ChannelsForTracking
): void {
  const assessmentCompletedEnabledOn = pipeChannelsEnabled(
    channelsForTrackingMerge
  );

  const BUCKET_VALUES: Record<AdBuckets, number> = {
    high: 100,
    medium: 1,
    low: 0,
  };
  const bucketValue = BUCKET_VALUES[bucket];

  if (assessmentCompletedEnabledOn("segment")) {
    segment.trackCustomEvent("wa_complete", {
      application_id,
      flow,
    });
  }
  if (assessmentCompletedEnabledOn("bing")) {
    bing.trackEvent("WrittenAssessmentCompleted");
  }
  if (assessmentCompletedEnabledOn("tiktok")) {
    tiktok.trackEvent("CompletePayment", {
      value: bucketValue,
    });
  }
  if (assessmentCompletedEnabledOn("google")) {
    ga.trackEvent({
      category: "Funnel",
      action: "WrittenAssessmentCompleted",
      label: leadContext,
      value: bucketValue,
    });
    ga4.trackEvent("WrittenAssessmentCompleted", {
      value: bucketValue,
    });
  }
  if (assessmentCompletedEnabledOn("linkedin")) {
    linkedin.trackEvent("WAC");
  }
}

export function assessmentResult(
  application_id: string,
  passed_word_count: string,
  wa_flow: string,
  is_qualified: boolean,
  sent_to: string,
  channelsForTrackingMerge?: ChannelsForTracking
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("wa_result", {
      application_id: application_id,
      passed_word_count: passed_word_count,
      wa_flow: wa_flow,
      status: is_qualified ? "qualified" : "unqualified",
      sent_to: sent_to,
    });
  }
}

export function interviewBookingStarted(
  channelsForTrackingMerge?: ChannelsForTracking
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("ivb_start");
  }
}

export function demographicsStarted(
  channelsForTrackingMerge?: ChannelsForTracking
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("demog_start");
  }
}

export function demographicsCompleted(
  channelsForTrackingMerge?: ChannelsForTracking
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("demog_complete");
  }
}

export function resumeError(
  reason: "invalid_type" | "invalid_file" | "upload_issue",
  channelsForTrackingMerge?: ChannelsForTracking
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.trackCustomEvent("resume_error", {
      reason,
    });
  }
}

export function identifyUser(
  userId?: string,
  userData?: object,
  channelsForTrackingMerge?: ChannelsForTracking
): void {
  if (isChannelEnabled(channelsForTrackingMerge, "segment")) {
    segment.identifyUser(userId, userData);
  }
}

export function enrollmentStarted(application_id: string) {
  segment.trackCustomEvent("enroll_start", { application_id });
}

export function enrollmentStepStarted(
  application_id: string,
  step_number: number,
  step_name: string
) {
  segment.trackCustomEvent("enroll_step_start", {
    application_id,
    step_number,
    step_name,
  });
}

export function enrollmentStepCompleted(
  application_id: string,
  step_number: number,
  step_name: string
) {
  segment.trackCustomEvent("enroll_step_complete", {
    application_id,
    step_number,
    step_name,
  });
}

export function enrollmentEnd(application_id: string) {
  segment.trackCustomEvent("enroll_end", { application_id });
}

export function brochureRequest() {
  segment.trackCustomEvent("brochure_request");
}

export function cohortChoice(cohortNumber: number | null) {
  segment.trackCustomEvent("app_cohort_choice", {
    app_cohort_choice: cohortNumber ? "next" : "later",
  });
}
