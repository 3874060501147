import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { MutableRefObject, useEffect, useRef, useState } from "react";

import { keepUTMs } from "lib/utils";

import { UUID_VALIDATION_REDIRECT_PATH } from "./constants";
import { validateUUID } from "./services";
import { getGclid, setGclid } from "./tracker/gtag";
import { getWebVisitUrl, setWebVisitUrl } from "./tracker/web-visit";

export function useScrollTo<T extends Element>(
  options: ScrollIntoViewOptions = {}
): [MutableRefObject<T>, () => void] {
  const defaultOption: ScrollIntoViewOptions = { behavior: "smooth" };
  const refElement = useRef<T | null>(null);
  const executeScroll = () =>
    refElement.current?.scrollIntoView({ ...defaultOption, ...options });

  return [refElement, executeScroll];
}

export function useBackgroundOnScroll(breakpoint: number): boolean {
  const [withBackground, showWithBackground] = useState(false);

  const listenScroll = () => {
    if (window.scrollY <= breakpoint) {
      showWithBackground(false);
    }
    if (window.scrollY > breakpoint && !withBackground) {
      showWithBackground(true);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", listenScroll);
    return () => {
      document.removeEventListener("scroll", listenScroll);
    };
  });

  return withBackground;
}

export function useKeepUTMs() {
  const router = useRouter();
  useEffect(() => {
    keepUTMs(router);
  }, []);
}

export const useAnimationFrame = (run: boolean, callback: () => void) => {
  const timerId = useRef(0);

  useEffect(() => {
    if (run) {
      timerId.current = requestAnimationFrame(callback);
    }

    return () => cancelAnimationFrame(timerId.current);
  }, [run, callback]);
};

export function useValidateUUID(uuid: string) {
  const router = useRouter();
  useEffect(() => {
    async function validateApplicationId() {
      const response = await validateUUID(uuid);
      if (response?.ok) {
        const data = await response.json();
        if (!data.is_valid) {
          router.push(UUID_VALIDATION_REDIRECT_PATH);
        }
      } else {
        if (response?.status >= 500) {
          Sentry.captureMessage("Server error when validating UUID", {
            extra: { response },
          });
        } else if (response?.status >= 400) {
          Sentry.captureMessage("Client error when validating UUID", {
            extra: { response },
          });
        }
        router.push(UUID_VALIDATION_REDIRECT_PATH);
      }
    }
    validateApplicationId();
  }, [uuid]);
}

/**
 * useScrollSpy hook
 * receives an array of element ids and returns the current active element id when scrolling
 * @param elementIds the array of element ids - make sure this is static with useMemo or similar
 * @param options the options object, supports an offsetElementId to offset the scrollspy - make sure this is static with useMemo or similar
 * @returns the current active element id
 * @see Idea: https://www.smashingmagazine.com/2021/07/dynamic-header-intersection-observer/
 * @see Considerations: https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5
 */
export const useScrollspy = <T extends string[]>(
  elementIds: T,
  options?: {
    offsetElementId?: string;
  }
) => {
  const [currentItem, setCurrentItem] = useState<T[number] | undefined>();

  const observer = useRef<IntersectionObserver>();
  const direction = useRef<"up" | "down" | "none">("up");
  const prevYPosition = useRef(0);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    const elements = elementIds.map((item) =>
      document.querySelector(`section[id="${item}"]`)
    );

    const offsetElement = document.querySelector(
      options?.offsetElementId
    ) as HTMLElement;

    const setScrollDirection = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop === prevYPosition.current || prevYPosition.current === 0) {
        direction.current = "none";
      } else if (scrollTop > prevYPosition.current) {
        direction.current = "down";
      } else {
        direction.current = "up";
      }

      prevYPosition.current = scrollTop;
    };

    const getTargetSection = (target: Element) => {
      if (direction.current === "up") return target;

      return target.nextElementSibling || target;
    };

    const shouldUpdate = (entry: IntersectionObserverEntry) => {
      if (direction.current === "down" && !entry.isIntersecting) {
        return true;
      }

      if (direction.current === "up" && entry.isIntersecting) {
        return true;
      }

      return false;
    };

    const onIntersect: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        setScrollDirection();

        if (!shouldUpdate(entry)) return;

        const target = getTargetSection(entry.target);
        setCurrentItem(target.id);
      });
    };

    observer.current = new IntersectionObserver(onIntersect, {
      rootMargin: offsetElement
        ? `${offsetElement.offsetHeight * -1}px`
        : undefined,
      threshold: 0.05,
    });

    const { current: currentObserver } = observer;

    elements.forEach((element) =>
      element ? currentObserver.observe(element) : null
    );

    return () => currentObserver.disconnect();
  }, [elementIds, options]);

  return currentItem;
};

export function useWordCount() {
  // word count
  const [wordCount, setWordCount] = useState(0);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value.split(/\s+/);
    const currentCount = text.reduce((acc, word) => {
      if (word.trim() !== "") {
        acc++;
      }
      return acc;
    }, 0);

    setWordCount(currentCount);
  };
  return [wordCount, onChange] as const;
}

export function useFirstVisit() {
  useEffect(() => {
    const webVisitUrl = getWebVisitUrl();
    const gclid = getGclid();
    if (!webVisitUrl) {
      setWebVisitUrl();
    }

    if (!gclid) {
      setGclid();
    }
  });
}
