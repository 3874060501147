import { SocialNetwork } from "components/organisms/Footer";

export const SOCIAL_NETWORKS: SocialNetwork[] = [
  {
    icon: "fa-brands fa-instagram",
    link: "https://www.instagram.com/svahq",
  },
  {
    icon: "fa-brands fa-linkedin",
    link: "https://www.linkedin.com/school/svacademy",
  },
  {
    icon: "fa-brands fa-twitter",
    link: "https://twitter.com/svahq",
  },
];
