// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://7eab0c867baa42d99dfea225e0ca9417@o323908.ingest.sentry.io/6081631",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled: process.env.NODE_ENV === "production",
  // Use vercel's environment variable to separate errors from Preview & Production
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ["api.sv.academy", "localhost"],
    }),
  ],
});
