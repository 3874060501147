import { useEffect, useState } from "react";

import * as tracker from "lib/tracker";

import { Variant } from "./flagsmith";
import { useFlagsmith } from "./flagsmith/flagsmith-browser";

export function isPathInPathList(pageList: string[] = [], path = "") {
  const [pathname] = path.split("?");
  return pageList.some((page) => pathname === page);
}

export function getVariantValueWithTargetPages(
  path: string,
  variant: Variant,
  defaultValue
) {
  if (variant && isPathInPathList(variant?.targetPages, path)) {
    return variant.value;
  }

  return defaultValue;
}

export function useExperimentVariant(
  experimentId,
  variant,
  currentPath,
  defaultValue
) {
  const { isInitialized } = useFlagsmith();
  const [variable, setVariable] = useState("");

  useEffect(() => {
    const variantValue = getVariantValueWithTargetPages(
      currentPath,
      variant,
      defaultValue
    );
    setVariable(variantValue);
  }, [currentPath, variant, defaultValue]);

  useEffect(() => {
    if (isInitialized) {
      tracker.trackExperiment(currentPath);
      if (isPathInPathList(variant?.targetPages, currentPath)) {
        tracker.trackExperiment(experimentId);
      }
    }
  }, [currentPath, isInitialized, variant?.targetPages, experimentId]);

  return [variable];
}
