import { Tryer } from "lib/tracker/utils";

interface LinkedInTracker {
  (...params: unknown[]): void;
  q?: unknown[];
}
declare global {
  interface Window {
    _linkedin_data_partner_ids: string[];

    lintrk: LinkedInTracker;
  }
}
const linkedin_partner_id = "2699410";

const ConversionsMap = {
  MQL: "7730628",
  Lead: "7417876",
  WAC: "7417884",
};
/* eslint-disable unicorn/prefer-query-selector */
export function init() {
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(linkedin_partner_id);
  (function (l) {
    if (!l) {
      window.lintrk = function (a, b) {
        window.lintrk.q.push([a, b]);
      };
      window.lintrk.q = [];
    }
    const s = document.getElementsByTagName("script")[0];
    const b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  })(window.lintrk);
}
/* eslint-enable unicorn/prefer-query-selector */

const tryTracking = Tryer("lintrk", init);

export function trackEvent(eventName: keyof typeof ConversionsMap) {
  tryTracking(
    "linkedin.trackEvent",
    () => window.lintrk("track", { conversion_id: ConversionsMap[eventName] }),
    { eventName: ConversionsMap[eventName] }
  );
}

export function trackPageView() {
  tryTracking(
    "linkedin.trackPageView",
    () => {
      const url = `https://px.ads.linkedin.com/collect/?pid=${linkedin_partner_id}&fmt=gif`;
      // Create an img element on the fly, then with the src do the call
      // to the LinkedIn servers.
      const element = document.createElement("img");
      element.alt = "";
      element.height = 1;
      element.width = 1;
      element.src = url;
    },
    {}
  );
}
