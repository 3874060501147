import Script from "next/script";

export default function ReferralRockScript() {
  return (
    <>
      <Script id="referralJS">
        {`
         window.referralJS = ((window.referralJS !== null && window.referralJS !== undefined) ? window.referralJS : {});
         window.referralJS.scriptConfig = { parameters: { src: "//svacademy.referralrock.com/ReferralSdk/referral.js", transactionKey: "65aff161-d70b-4ed7-bf66-b9b583ec2230" } };
         (function(f,r,n,d,b,y){b=f.createElement(r),y=f.getElementsByTagName(r)[0];b.async=1;b.src=n+"?referrer="+encodeURIComponent(window.location.origin+window.location.pathname).replace(/[!'()*]/g,escape);b.id="RR_DIVID_V5";b.setAttribute("transactionKey",window.referralJS.scriptConfig.parameters.transactionKey);y.parentNode.insertBefore(b,y)})(document,"script",window.referralJS.scriptConfig.parameters.src);
        `}
      </Script>
    </>
  );
}
