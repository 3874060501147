import clsx from "clsx";

import SVAcademyLogo from "components/atoms/SVAcademyLogo";

import classnames, { Spacing } from "lib/classnames";
import { getHexFromColorName } from "lib/tailwind";

import { BackgroundColors, TextColors } from "types/theme.types";

type Props = {
  color: TextColors;
  bg: BackgroundColors;
  spacing: Spacing[];
};

export default function SVLogoBar(props: Props) {
  const fill = getHexFromColorName(props.color);
  const containerClassName = clsx(
    "flex",
    "justify-center",
    props.bg,
    classnames.spacing(props.spacing)
  );
  return (
    <div className={containerClassName}>
      <SVAcademyLogo className="hidden md:block" fill={fill} height={30} />
      <SVAcademyLogo className="md:hidden" fill={fill} height={21} />
    </div>
  );
}
