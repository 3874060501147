import * as Sentry from "@sentry/nextjs";

export function Tryer(windowKey, init) {
  return function (name, func, extra) {
    if (process.env.NEXT_PUBLIC_LOG_TRACKING_ONLY) {
      console.log(name, extra);
      return;
    }

    if (!window[windowKey]) {
      init();
    }

    try {
      func();
    } catch (error) {
      Sentry.captureException(error, { extra: extra });
    }
  };
}
