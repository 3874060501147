function isLocalStorageFunctionAvailable(name: keyof Storage) {
  try {
    return typeof window?.localStorage?.[name] === "function";
  } catch {
    return false;
  }
}

export function getItem(key) {
  if (isLocalStorageFunctionAvailable("getItem")) {
    return window.localStorage.getItem(key);
  }
  return null;
}

export function setItem(key, value) {
  if (isLocalStorageFunctionAvailable("setItem")) {
    return window.localStorage.setItem(key, value);
  }
  return null;
}

export default {
  getItem,
  setItem,
};
