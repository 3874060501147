import { CacheKeys } from "enum/cache-keys";

const ENVIRONMENT_ID = process.env.NEXT_PUBLIC_FLAGSMITH_ENVIRONMENT_KEY;
const API = "https://api.flagsmith.com/api/v1/";

type VariantValue = string | boolean | Record<string, unknown>;

export type Variant = {
  value: VariantValue;
  name: string;
  targetPages?: string[];
  isPageExperiment?: boolean;
  [key: string]: unknown;
};
export type VariantHash = { [experimentId: string]: Variant };

export type Experiment = {
  id: string;
  name: string;
  enabled: boolean;
  isPageExperiment?: boolean;
  defaultVariant: Variant;
};
export type ExperimentHash = { [experimentId: string]: Experiment };

export type ExperimentData = {
  experimentId: string;
  experimentName: string;
  variantValue: VariantValue;
  variantName: string;
};

const getJSON = async (url: string, body?: BodyInit) => {
  const options = {
    method: "GET",
    body,
    headers: {
      "x-environment-key": ENVIRONMENT_ID,
    },
  };

  const response = await fetch(API + url, options);
  return response.json();
};

const parseVariant = (variant: string) => {
  let parsedVariant: Variant = null;
  try {
    parsedVariant = JSON.parse(variant);
  } catch {
    console.warn("Unsupported variant. Variant needs to be a valid json");
  }
  return parsedVariant;
};

export type Cacher = {
  restoreExperiments: () => ExperimentHash | null;
  saveExperiments: (experimentHash: ExperimentHash) => void;
  restoreUserVariants: (identity: string) => VariantHash | null;
  saveUserVariants: (identity: string, variantHash: VariantHash) => void;
};

export const experimentsCacheKey = () => CacheKeys.flagsmithExperimentsCache;

export const userVariantsCacheKey = (identity: string) =>
  CacheKeys.flagsmithUserVariantsCache + identity;

export class Flagsmith {
  isInitialized = false;
  isRestored = false;
  experiments: ExperimentHash = {};
  userVariants: VariantHash = {};
  cacher?: Cacher | null;

  constructor(cacher?: Cacher) {
    if (cacher) {
      this.cacher = cacher;
    }
  }

  restore(identity: string) {
    if (!this.cacher) {
      console.warn("No Flagsmith cacher defined, cannot restore");
      return;
    }

    if (this.isInitialized || this.isRestored) {
      console.log("Flagsmith data already fetched.");
      return;
    }

    const cachedExperiments = this.cacher.restoreExperiments();
    if (cachedExperiments) {
      this.experiments = cachedExperiments;
    } else {
      console.warn("Could not restore Flagsmith experiments");
    }

    const cachedUserVariants = this.cacher.restoreUserVariants(identity);
    if (cachedUserVariants) {
      this.userVariants = cachedUserVariants;
    } else {
      console.warn("Could not restore Flagsmith userVariants");
    }

    this.isRestored = true;
  }

  async init(identity?: string) {
    if (this.isInitialized) return;

    const promises = [this.getExperiments()];
    if (identity) {
      promises.push(this.getUserVariants(identity));
    }
    await Promise.all(promises);
    this.isInitialized = true;
  }

  async getExperiments() {
    try {
      const experiments = await getJSON("flags/");
      experiments.forEach(({ feature, enabled, feature_state_value }) => {
        const { name, description, isPageExperiment } = feature;
        this.experiments[name] = {
          id: name,
          name: description,
          defaultVariant: parseVariant(feature_state_value),
          enabled,
          isPageExperiment,
        };
      });
      if (this.cacher) {
        this.cacher.saveExperiments(this.experiments);
      }
    } catch {
      console.error("Unable to fetch experiments from flagsmith");
    }
  }

  async getUserVariants(identity: string) {
    try {
      const response = await getJSON(
        "identities/?identifier=" + encodeURIComponent(identity)
      );
      const { flags: variants } = response;
      variants.forEach(({ feature, feature_state_value }) => {
        this.userVariants[feature.name] = {
          ...parseVariant(feature_state_value),
        };
      });
      if (this.cacher) {
        this.cacher.saveUserVariants(identity, this.userVariants);
      }
    } catch {
      console.error("Unable to fetch user variants from flagsmith");
    }
  }

  getExperiment(name: string) {
    return (
      this.experiments?.[name] ??
      Object.values(this.experiments).find(
        (experiment) =>
          experiment.defaultVariant?.isPageExperiment &&
          !!experiment.defaultVariant?.value[name]
      )
    );
  }

  getPageLevelUserVariant(name: string) {
    return Object.values(this.userVariants).find(
      (variant) => variant.isPageExperiment && !!variant.value[name]
    );
  }

  getUserVariant(name: string) {
    const userVariant =
      this.userVariants?.[name] ?? this.getPageLevelUserVariant(name);

    const defaultVariant = this.getExperiment(name)?.defaultVariant;

    return userVariant || defaultVariant;
  }

  getExperimentData(name: string): ExperimentData {
    const { name: experimentName, id: experimentId } = this.getExperiment(name);
    const { name: variantName, value: variantValue } =
      this.getUserVariant(name);

    return {
      experimentId,
      experimentName,
      variantName,
      variantValue,
    };
  }

  hasExperiment(name: string): boolean {
    const experiment = this.getExperiment(name);
    return experiment?.enabled;
  }

  hasPageExperiment(name: string) {
    return this.hasExperiment(name);
  }
}
