import { parseCookies } from "nookies";
import { useEffect, useState } from "react";

import { getAnonymousId } from "lib/anonymous-id";
import {
  Cacher,
  experimentsCacheKey,
  Flagsmith,
  userVariantsCacheKey,
  VariantHash,
} from "lib/flagsmith";
import { jsonParser, jsonStringifier } from "lib/json-handler";
import localStorageWrapper from "lib/local-storage-wrapper";

const BROWSER_CACHER: Cacher = {
  restoreExperiments() {
    const cacheKey = experimentsCacheKey();
    const cookies = parseCookies();
    const rawValue = localStorageWrapper.getItem(cacheKey) || cookies[cacheKey];
    if (rawValue) {
      return jsonParser(rawValue);
    }
  },

  saveExperiments(experimentHash) {
    localStorageWrapper.setItem(
      experimentsCacheKey(),
      jsonStringifier(experimentHash)
    );
  },

  restoreUserVariants(identity: string) {
    const cacheKey = userVariantsCacheKey(identity);
    const cookies = parseCookies();
    const rawValue = localStorageWrapper.getItem(cacheKey) || cookies[cacheKey];
    if (rawValue) {
      return jsonParser(rawValue);
    }
  },

  saveUserVariants(identity: string, variantHash: VariantHash) {
    localStorageWrapper.setItem(
      userVariantsCacheKey(identity),
      jsonStringifier(variantHash)
    );
  },
};

export const flagsmithBrowser = new Flagsmith(BROWSER_CACHER);

export function useFlagsmith() {
  const [isInitialized, setIsInitialized] = useState(
    flagsmithBrowser.isInitialized
  );
  const [isRestored, setIsRestored] = useState(flagsmithBrowser.isRestored);

  useEffect(() => {
    const id = getAnonymousId();

    flagsmithBrowser.restore(id);
    setIsRestored(flagsmithBrowser.isRestored);

    flagsmithBrowser.init(id).then(() => {
      setIsInitialized(flagsmithBrowser.isInitialized);
    });
  }, []);

  return { isInitialized, isRestored };
}

export function useVariant(experimentId: string) {
  const { isRestored, isInitialized } = useFlagsmith();

  const getVariant = (id: string) =>
    flagsmithBrowser.hasExperiment(id) && flagsmithBrowser.getUserVariant(id);

  const [variant, setVariant] = useState(getVariant(experimentId));

  useEffect(() => {
    if (isRestored || isInitialized) {
      setVariant(getVariant(experimentId));
    }
  }, [isRestored, isInitialized, experimentId]);

  return variant;
}
