import Script from "next/script";

export default function OneTrustScript() {
  return (
    <>
      <Script
        src={"https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"}
        data-domain-script="d9044d94-4529-445a-8771-bd27c5cabbb8"
      />
      <Script id="one-trust">
        {`
         function OptanonWrapper() {}
        `}
      </Script>
    </>
  );
}
