// TODO:
//
// @Alizain
//
// Add test cases for this
const COMBINED_REGEX =
  /(https?:\/\/)?(www.)?linkedin.com\/(mwlite\/)?in\/([\w-]+)/;
const PROFILE_INDEX_IN_MATCH = 4;

export function parseLinkedInProfile(value) {
  if (value && typeof value.match === "function") {
    const result = value.match(COMBINED_REGEX);
    if (result && result[PROFILE_INDEX_IN_MATCH]) {
      return result[PROFILE_INDEX_IN_MATCH];
    }
  }
  return false;
}
