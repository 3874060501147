import { parseCookies } from "nookies";
import ReactGA from "react-ga";

import { Tryer } from "lib/tracker/utils";

type EventProperties = {
  category: string;
  action: string;
  label: string;
  value?: number;
  nonInteraction?: boolean;
};

export const init = (): void => {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_ID);
};

const tryTracking = Tryer("ga", init);

export function trackPageView(): void {
  const page = window.location.pathname;
  tryTracking(
    "google.trackPageView",
    () => {
      ReactGA.set({ page });
      ReactGA.pageview(page);
    },
    {
      page,
    }
  );
}

export function trackEvent(properties: EventProperties): void {
  tryTracking(
    "google.trackEvent",
    () => ReactGA.event({ ...properties }),
    properties
  );
}

export async function getClientId() {
  return new Promise<string | undefined | void>((res) => {
    window["ga"]
      ? window["ga"]((tracker) => {
          try {
            res(tracker.get("clientId"));
          } catch {
            res();
          }
        })
      : res();
  });
}

export function setUserId(userId: string): void {
  ReactGA.set({ userId });
}

export function getGoogleUACookies(): { google_ga_cookie: string } {
  const { _ga = "" } = parseCookies();
  return { google_ga_cookie: _ga };
}
