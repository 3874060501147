import Link from "next/link";

import SVAcademyLogo from "components/atoms/SVAcademyLogo";

type Props = {
  href?: string;
  height?: number;
  onClick?: () => void;
};
export default function NavbarSVLogoLink({
  href = "/",
  onClick,
  height = 30,
}: Props) {
  return (
    <Link href={href}>
      <a className="leading-none" onClick={onClick}>
        <SVAcademyLogo className="translate-y-[2px]" height={height} />
      </a>
    </Link>
  );
}
