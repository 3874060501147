import { Tryer } from "lib/tracker/utils";

declare global {
  interface Window {
    uetq: {
      push: (...params: unknown[]) => void;
    };
  }
}

// From Microsoft
/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable unicorn/prefer-add-event-listener */
/* eslint-disable @typescript-eslint/ban-ts-comment */
export function init() {
  (function (w, d, t, r, u) {
    let f, n, i;
    (w[u] = w[u] || []),
      (f = function () {
        const o = {
          ti: "137032737",
        };
        // @ts-ignore
        (o.q = w[u]), (w[u] = UET ? new UET(o) : []); // this line differs with original microsoft code. It is meant to avoid throwing errors if adblockers block the script.
      }),
      (n = d.createElement(t)),
      (n.src = r),
      (n.async = 1),
      (n.onload = n.onreadystatechange =
        function () {
          const s = this.readyState;
          (s && s !== "loaded" && s !== "complete") ||
            (f(), (n.onload = n.onreadystatechange = null));
        }),
      (i = d.getElementsByTagName(t)[0]),
      i.parentNode.insertBefore(n, i);
  })(window, document, "script", "//bat.bing.com/bat.js", "uetq");
}
/* eslint-enable unicorn/prefer-query-selector */
/* eslint-enable unicorn/prefer-add-event-listener */
/* eslint-enable @typescript-eslint/ban-ts-comment */

const tryTracking = Tryer("uetq", init);

export function trackEvent(
  eventName: string,
  params?: Record<string, unknown>
) {
  tryTracking(
    "bing.trackEvent",
    () => window.uetq.push("event", eventName, params),
    {
      eventName,
      params,
    }
  );
}

export function trackPageView() {
  trackEvent("page_view", { page_path: window.location.pathname });
}
