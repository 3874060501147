import { getYear } from "date-fns";
import { CacheKeys } from "enum/cache-keys";
import { includes } from "lodash";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import mapKeys from "lodash/mapKeys";
import omitBy from "lodash/omitBy";
import snakeCase from "lodash/snakeCase";
import { ImageProps } from "next/image";
import { NextRouter } from "next/router";
import { parseCookies, setCookie } from "nookies";

import {
  notAllowedHighestLevelOfEducationValues,
  requiredMaxGradYearAgePerDegree,
} from "config/potentialQualifiedLeadCriteria";
import UTMKeys from "config/utm-keys.json";

import localStorageWrapper from "lib/local-storage-wrapper";

import {
  AdBuckets,
  AssessmentApplicant,
  AssessmentResponse,
} from "types/assessment.types";
import { ParsedUrlQuery, ProgramType, UTMs } from "types/common.types";

import { ChannelsForTracking } from "./tracker";

export const path = {
  join: (...args: string[]): string => compact(args).join("/"),
};

export const formatImage = (image: Partial<ImageProps>) => ({ image });

export function snakeCaseObject<T extends object>(
  object: T
): Record<string, unknown> {
  return Object.keys(object).reduce((reduce: T, key: string) => {
    const child = object[key];
    if (Array.isArray(child)) {
      return {
        ...reduce,
        [snakeCase(key)]: child.map((item) => {
          if (isPlainObject(item)) return snakeCaseObject(item);
          return item;
        }),
      };
    }
    return {
      ...reduce,
      [snakeCase(key)]: isPlainObject(child)
        ? snakeCaseObject(child as T)
        : child,
    };
  }, {} as T);
}

export function getQueryParameterAtIndex(
  query: ParsedUrlQuery,
  key: string,
  index = 0
): string | null {
  if (query[key]) {
    if (Array.isArray(query[key])) {
      return query[key][index];
    } else {
      return query[key] as string;
    }
  }
  return null;
}

export function getUTMSFromStorage(type: "first" | "last"): UTMs {
  const utms = UTMKeys.reduce((utmsObject, utmKey) => {
    const key = type === "first" ? `first_${utmKey}` : utmKey;
    const value = localStorageWrapper.getItem(key);

    return { ...utmsObject, [utmKey]: value };
  }, {});

  return (includes(utms, "chilipiper") ? {} : utms) as UTMs;
}

export function getKeyFromStorage(key: CacheKeys) {
  const cookies = parseCookies();
  return localStorageWrapper.getItem(key) || cookies[key];
}

export function setStorageKey<T extends string>(key: CacheKeys, value: T) {
  setCookie(null, key, value, {
    maxAge: 10 * 365 * 24 * 60 * 60, // 10 years, very long time so it doesn't expire
    path: "/",
  });
  localStorageWrapper.setItem(key, value);
}

export function keepUTMs(router: NextRouter) {
  const query = getQueryFromRouterPath(router);
  const hasAtLeastOneUTMasQuery = Object.keys(query).some((item) =>
    UTMKeys.includes(item)
  );

  const hasUTMsStored = UTMKeys.some((utmKey) =>
    localStorageWrapper.getItem(`first_${utmKey}`)
  );

  if (hasAtLeastOneUTMasQuery) {
    setStorageKey(CacheKeys.lastVisitUrl, window.location.href);

    // We store the first web visit utms if they're not set, then we keep storing the last visit ones
    UTMKeys.forEach((utmKey) => {
      const value = (query[utmKey] as string) || "";

      if (!hasUTMsStored) {
        localStorageWrapper.setItem(`first_${utmKey}`, value);
      }

      localStorageWrapper.setItem(utmKey, value);
    });
  }
}

export function getQueryFromRouter(router: NextRouter) {
  return isEmpty(router.query) ? getQueryFromRouterPath(router) : router.query;
}

export function getQueryFromRouterPath(router: NextRouter): ParsedUrlQuery {
  const params = new URLSearchParams(router?.asPath?.split("?")[1] || "");
  return Object.fromEntries(params.entries());
}

export function isGradYearValid(
  highestLevelOfEducation: string,
  graduationYear: number
): boolean {
  const gradYearDiff = getYear(new Date()) - graduationYear;

  if (
    notAllowedHighestLevelOfEducationValues.includes(highestLevelOfEducation)
  ) {
    return false;
  }

  return (
    gradYearDiff <= requiredMaxGradYearAgePerDegree[highestLevelOfEducation]
  );
}

export function prefixKeys<T extends object>(object: T, prefix: string) {
  return mapKeys(object, (_, key) => `${prefix}${key}`);
}

export function isValidProgram(programName: string | ProgramType) {
  const validProgramNames = ["sdr", "cs", "unsure"];
  return validProgramNames.includes(programName);
}

export function removeNullishEntries<T extends Record<string, unknown>>(
  object: T
) {
  return omitBy(object, (val) => val == null || val === "") as Partial<T>;
}

export function getProgramFromQuery(query: ParsedUrlQuery) {
  const program = getQueryParameterAtIndex(query, "program")?.toLowerCase();

  if (!isValidProgram(program)) {
    return "sdr";
  }

  return program as ProgramType;
}

export function getApplicationIdFromQuery(query) {
  return (
    getQueryParameterAtIndex(query, "id") ||
    getQueryParameterAtIndex(query, "uuid") ||
    getQueryParameterAtIndex(query, "external_id")
  );
}

export function getQueueTypeFromQuery(query: ParsedUrlQuery) {
  return getQueryParameterAtIndex(query, "queue") || "interview-queue";
}

export function hasOwnProperty(value: unknown, property: string | number) {
  return Object.prototype.hasOwnProperty.call(value ?? {}, property);
}

export const calculateWACScore = (
  applicant: AssessmentResponse["applicant"]
): AdBuckets => {
  if (applicant.employment_status?.value === "full_time") {
    return "low";
  }

  return applicant.employment_status?.value === "unemployed"
    ? "high"
    : "medium";
};
