import { parseCookies } from "nookies";

import { Tryer } from "lib/tracker/utils";

declare global {
  interface Window {
    fbq: (...args: unknown[]) => void;
    _fbq: (...args: unknown[]) => void;
  }
}

const PIXELS = {
  "2023": "1952578601704343",
};

export function getPixelIds() {
  return Object.values(PIXELS);
}

// From Facebook
/* eslint-disable unicorn/prefer-dom-node-append */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
export function init() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.defer = true; // t.async = !0;
    t.src = v;
    // s = b.getElementsByTagName(e)[0];
    // s.parentNode.insertBefore(t, s)
    b.body.appendChild(t);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );
  // Init Facebook Pixel IDs
  getPixelIds().forEach((id) => window.fbq("init", id));
}
/* eslint-enable unicorn/prefer-dom-node-append */
/* eslint-enable prefer-rest-params */
/* eslint-enable prefer-spread */

const tryTracking = Tryer("fbq", init);

export function track(
  eventType: string,
  eventName: string,
  params?: Record<string, unknown>
) {
  tryTracking(
    "facebook.track",
    () => window.fbq(eventType, eventName, params),
    {
      eventType,
      eventName,
      params,
    }
  );
}

export function getFacebookCookies() {
  const { _fbc = "", _fbp = "" } = parseCookies();
  return { fb_fbc_cookie: _fbc, fb_fbp_cookie: _fbp };
}
