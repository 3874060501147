import Link from "next/link";
import { ReactNode } from "react";

import classnames, { ButtonType } from "lib/classnames";

interface Props {
  href: string;
  type: ButtonType;
  children: ReactNode;
}

export default function NavbarLinkButton(props: Props) {
  const linkButtonClassNames = classnames.navbarButton(props.type);

  return (
    <Link passHref={true} href={props.href}>
      <a className={linkButtonClassNames}>{props.children}</a>
    </Link>
  );
}
