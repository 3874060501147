import clsx from "clsx";
import { ReactNode } from "react";

export default function Section(props: {
  children?: ReactNode;
  className?: string;
  containerClassName?: string;
  containerMargin?: string;
  id?: string;
  anchorWithNavbarOffset?: boolean;
}) {
  const hasAnchorWithNavbarOffset = props.id && props.anchorWithNavbarOffset;

  const className = clsx(
    "w-full",
    "flex",
    "justify-center",
    props.className,
    hasAnchorWithNavbarOffset && "relative"
  );

  const containerClassName = clsx(
    "container",
    "p-0",
    "w-full",
    "bg-transparent",
    props.containerMargin || "mx-4",
    props.containerClassName
  );

  return (
    <section
      id={hasAnchorWithNavbarOffset ? null : props.id}
      className={className}
    >
      {hasAnchorWithNavbarOffset && (
        <div id={props.id} className="absolute -top-14 md:-top-20"></div>
      )}
      <div className={containerClassName}>{props.children}</div>
    </section>
  );
}
