import { Tryer } from "lib/tracker/utils";

declare global {
  interface Window {
    TiktokAnalyticsObject: unknown;
    ttq: {
      track: (...args: unknown[]) => void;
      page: (...args: unknown[]) => void;
    };
  }
}

/* eslint-disable unicorn/prefer-spread */
/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable unicorn/prefer-date-now */
/* eslint-disable unicorn/prefer-query-selector */
export function init() {
  (function (w, d, t) {
    w.TiktokAnalyticsObject = t;
    const ttq = (w[t] = w[t] || []);
    (ttq.methods = [
      "page",
      "track",
      "identify",
      "instances",
      "debug",
      "on",
      "off",
      "once",
      "ready",
      "alias",
      "group",
      "enableCookie",
      "disableCookie",
    ]),
      (ttq.setAndDefer = function (t, e) {
        t[e] = function () {
          // eslint-disable-next-line prefer-rest-params
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      });
    for (let i = 0; i < ttq.methods.length; i++)
      ttq.setAndDefer(ttq, ttq.methods[i]);
    (ttq.instance = function (t) {
      // eslint-disable-next-line no-var
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
        ttq.setAndDefer(e, ttq.methods[n]);
      return e;
    }),
      (ttq.load = function (e, n) {
        const i = "https://analytics.tiktok.com/i18n/pixel/events.js";
        (ttq._i = ttq._i || {}),
          (ttq._i[e] = []),
          (ttq._i[e]._u = i),
          (ttq._t = ttq._t || {}),
          (ttq._t[e] = +new Date()),
          (ttq._o = ttq._o || {}),
          (ttq._o[e] = n || {});
        const o = document.createElement("script");
        (o.type = "text/javascript"),
          (o.async = !0),
          (o.src = i + "?sdkid=" + e + "&lib=" + t);
        const a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(o, a);
      });

    ttq.load(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID);
  })(window, document, "ttq");
}
/* eslint-enable unicorn/prefer-spread */
/* eslint-enable unicorn/prevent-abbreviations */
/* eslint-enable unicorn/prefer-date-now */
/* eslint-enable unicorn/prefer-query-selector */

const tryTracking = Tryer("ttq", init);

export function trackPageView() {
  tryTracking("tiktok.trackPageView", () => window.ttq.page(), {});
}

export function trackEvent(
  eventName: string,
  properties: Record<string, unknown> = {}
) {
  tryTracking(
    "tiktok.trackEvent",
    () => window.ttq.track(eventName, properties),
    {
      eventName,
    }
  );
}
