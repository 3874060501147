import { CacheKeys } from "enum/cache-keys";

import { getKeyFromStorage, setStorageKey } from "lib/utils";

type EventProperties = {
  value?: number;
};

declare global {
  interface Window {
    dataLayer: (string | Date)[];
    gtag: (...args: unknown[]) => void;
  }
}

export function trackEvent(name: string, properties?: EventProperties): void {
  window.gtag("event", name, properties);
}

export async function getClientId() {
  return new Promise<string | undefined>((res) => {
    window.gtag(
      "get",
      process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID,
      "client_id",
      res
    );
  });
}

export function setUserId(userId: string): void {
  window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID, {
    user_id: userId,
  });
}

const CacheKey = CacheKeys.gclickId;

export function getGclid(): string {
  return getKeyFromStorage(CacheKey);
}

export function setGclid() {
  const urlParams = new URLSearchParams(window.location.search);
  const gclid = urlParams.get("gclid");
  if (gclid) {
    setStorageKey(CacheKey, gclid);
  }
}
