import { SOCIAL_NETWORKS } from "data/footer";
import Link from "next/link";

import SVAcademyLogo from "components/atoms/SVAcademyLogo";
import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";

export type SocialNetwork = {
  icon: string;
  link: string;
};

export default function Footer() {
  const socialLinks = (
    <>
      <div className="flex mb-10 md:mb-8 text-green-pale">
        {SOCIAL_NETWORKS.map((socialNetwork, index) => (
          <a
            href={socialNetwork.link}
            key={`social-network-${index}`}
            className="mr-9 h-6"
          >
            <i className={`cursor-pointer h-6 ${socialNetwork.icon}`} />
          </a>
        ))}
      </div>
    </>
  );

  return (
    <footer>
      <Section className="bg-green-black pb-12 pt-16 md:pt-24 text-white">
        <div className="flex flex-col md:flex-row justify-between w-full">
          <div className="pb-12 md:pb-0 w-full md:w-1/2 lg:w-1/3 relative">
            <SVAcademyLogo fill={"#A9E8E0"} height={31} width={168.95} />
            <Typography.H5 className="mt-6 md:mt-8">
              On a mission to generate $100B in wealth expansion for
              underrepresented job seekers
            </Typography.H5>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3">
            {socialLinks}
            <Typography.Body>
              <a href="mailto:contact@sv.academy">contact@sv.academy</a>
              <br />
            </Typography.Body>
            <Typography.Body className="mt-8">
              <a href="https://sv-academy.breezy.hr">Work at SV Academy</a>
            </Typography.Body>
          </div>
        </div>

        <div className="bg-warm-light h-[1px] my-12 md:mt-16 md:mb-8 w-full" />

        <div className="flex flex-col md:flex-row justify-between">
          <Typography.Body className="mb-4 md:mb-0">
            {`© ${new Date().getFullYear()} SV Academy`}
          </Typography.Body>
          <Typography.Body>
            <Link href="/terms-and-conditions">
              <a>Terms and Conditions</a>
            </Link>
          </Typography.Body>
          <Typography.Body>
            <Link href="/privacy-policy">
              <a>Privacy Policy</a>
            </Link>
          </Typography.Body>
        </div>
      </Section>
    </footer>
  );
}
